.container {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    padding: 1rem;
    background-color: white;
    color: #1a202c;
    transition: background-color 0.3s, color 0.3s;
    z-index: 10;
  
    &.darkMode {
      background-color: #1a202c;
      color: white;
    }
  }
  
  .themeToggleDiv {
    position: fixed;
    top: 1rem;
    right: 1rem;
  }
  
  .skipButton {
    margin-top: 1rem;
    color: #5c775d;
    transition: color 0.3s;
    background: none;
    border: none;
  
    .darkMode & {
      color: #f7fbff;
    }
  }