.cardContainer {
  position: relative;
  width: 360px;
  height: 400px;
  user-select: none;
}

.card {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: show;
  cursor: grab;
  background: transparent;
  
  &:active {
    cursor: grabbing;
  }
  
  &.darkMode {
    .cardContent {
      background: rgba(45, 55, 72, 0.6);
    }
  }
}

.cardContent {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 20px;
  transition: background 0.3s ease;
  img{
    object-fit: contain;
  }
}

.cardBackground {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  transition: transform 0.3s ease;
}

.cardNumber {
  position: relative;
  z-index: 2;
  font-family: monospace;
  font-size: 1.2rem;
  color: white;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
}

.cardChip {
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 30px;
  background: linear-gradient(135deg, #ffd700 0%, #b8860b 100%);
  border-radius: 4px;
  z-index: 2;
}

.navigationDots {
  position: absolute;
  bottom: 16px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 8px;
  z-index: 2;
}

.dot {
  width: 8px;
  height: 8px;
  border: none;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
  padding: 0;
  transition: all 0.3s ease;

  &:hover {
    background-color: rgba(255, 255, 255, 0.8);
  }

  &.activeDot {
    background-color: white;
    transform: scale(1.2);
  }
}