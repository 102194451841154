.slideContent {
    width: 100%;
    max-width: 20rem;
    margin: 2rem;
    text-align: center;
  }
  
  .title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  
  .description {
    margin-bottom: 1rem;
  }