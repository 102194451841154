.toggle-button {
  position: fixed;
  bottom: 12%;
  right: 278px; /* Set the initial right position */
  width: 44px;
  height: 44px;
  transform: translateX(0); /* Initial transform state */
  z-index: 1001;
  justify-content: center;
  align-items: center;
  background-color: #1fd40f;
  border: 2px solid black;
  color: #fff;
  border: none;
  border-radius: 100%;
  cursor: pointer;
  transition: transform 500ms ease, right 400ms ease; /* Ensure right is also included in the transition */
  border: 1px solid rgb(208, 208, 208);
  
  &.closed {
    transform: translateX(230px); /* Adjust transform to animate */
  }

  &:hover {
    background-color: #00ff1a;
  }

  svg {
    cursor: pointer;
    stroke: rgb(22, 22, 22);
    stroke-width: 2;
    stroke-linecap: round;
    transition: transform 0.5s ease-in-out;
  }

  svg line {
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  }

  /* Initial closed state - no additional styles needed since this is the default state */
  .closed line:nth-child(1){
    transform: translate(0,6px);
  }
  
  .closed line:nth-child(2){
    transform-origin: center;
    transform: translate(0,0) rotate(90deg);
  }

  /* Open state transformations */
  .open line:nth-child(1) {
    transform: translate(8px,0) rotate(45deg);
  }

  .open line:nth-child(2) {
    transform: translate(-5px,12px) rotate(-45deg);
  }
}

.sidebar {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 300px;
  background-color: #041002;
  color: #fff;
  // transform: translateX(100%);
  transition: 400ms ease;
  z-index: 1000;

  &.open {
    width: 300px;
  }

  &.closed {
    width: 70px;
  }

  .sidebar-content {
    // padding: 20px;
    button {
      display: flex;
      flex-direction: row;
      background: none;
      border: none;
      color: #fff;
      padding: 2px 4px;
      cursor: pointer;
      justify-content: start;
      align-items: center;
      width: 80%;
      margin-left: 6px;
      border-radius: 18px;
      
      &.active{
        background: #17191D;
      }

      img {
        width: 42px;
        height: 42px;
        margin: 8px;
        background: white;
        padding: 8px;
        border-radius: 50%;
        filter: brightness(0.8);
        
        &.active{
          filter: brightness(1);
        }
      }
    }
  }

  .transact {
    display: flex;
    flex-direction: column;

    .opts {
      display: flex;
      flex-direction: row;
      button {
        padding: 10px;
        width: 100%;
        margin: 10px;
      }
    }
    .pricing {
      width: 80%;
      display: flex;
      flex-direction: column;
      margin: auto;
      border-top: 2px solid black;
      
      input {
        width: 100%;
      }
      span {
        margin: 8px;
      }
      button {
        padding: 12px;
        margin-top: 12px;
      }
    }
  }
}
