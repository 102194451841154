.ExpenseOptionsPanel {
    display: flex;
    gap: 20%;
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 96%;
    margin: 24px auto;

    // Media query for smaller screens
    @media (max-width: 768px) {
      flex-direction: column;
    }

    &.darkMode {
      background-color: #0d172b;
      color: white;

      select,input,button { 
        background-color: #0d172b;
        color: white;
        color-scheme: dark;
      }
      span{
        color: whitesmoke;
      }
        
    .CategorySelector {
    
      button {
        color: white;
      }
    }
    
    }
  }
  
  .CollapsibleSection {
    flex: 1; // Let both sections take equal width
    margin-bottom: 16px;
    button {
      width: 100%;
      padding: 10px;
      background-color: #ffffff;
      border: 1px solid #d1d5db;
      border-radius: 6px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      font-size: 16px;
      color: #333;
      font-weight: bold;
      transition: background-color 0.3s;
  
      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }
  
      span {
        font-size: 14px;
        color: #666;
      }
    }
  }
  
  .CategorySelector {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;
  
    button {
      padding: 8px 12px;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      font-size: 14px;
      transition: background-color 0.3s;
  
      &:hover {
        background-color: #3b82f6;
        color: white;
      }
    }
  }
  
  .DateRangeSelector {
    margin-top: 10px;
    select {
      width: 100%;
      padding: 8px;
      margin-bottom: 10px;
      font-size: 14px;
      border: 1px solid #d1d5db;
      border-radius: 4px;
      background-color: #fff;
      cursor: pointer;
    }
  
    .dateRange {
      display: flex;
      align-items: center;
      gap: 10px;
  
      input {
        padding: 8px;
        border: 1px solid #d1d5db;
        border-radius: 4px;
        font-size: 14px;
      }
  
      span {
        font-size: 14px;
        color: #666;
      }
    }
  }
  
  // Media query to adjust button sizes and layout on smaller screens
  @media (max-width: 768px) {
    .CategorySelector button,
    .DateRangeSelector input,
    .DateRangeSelector select {
      width: 100%; // Full width for buttons and inputs on smaller screens
    }
  
    .dateRange {
      flex-direction: column;
    }
  }
  
  .toggleGroup {
    display: inline-flex;
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), inset 10px 10px 10px -10px rgba(0, 0, 0, 0.1);
    margin: 32px auto;
    padding: 8px;
    background-color: white;
    &.darkMode{
      background-color: #0d172b;
      color: whitesmoke;
      .inactive {
        background-color: none;
        color: whitesmoke;
      }
    }
  }
  
  .button {
    padding: 8px 20px;
    font-size: 0.875rem;
    font-weight: 500;
    border-radius: 4px;
    border: none;
    background: none;
    transition: all 0.2s ease-in-out;
  }
  
  .inactive {
    background-color: none;
    color: #111827;
  }
  
  .incomeActive {
    background-color: var(--color-primary);
    color: white;
  }
  
  .expensesActive {
    background-color: var(--color-primary);
    color: white;
  }