.container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 100000;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.1);
  }

  .loadingAnimation {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
  }
  
  .dot {
    width: 0.5rem;
    height: 0.5rem;
    background-color: white;
    border-radius: 50%;
    animation: bounce 0.6s infinite alternate;
  
    &:nth-child(2) {
      animation-delay: 0.2s;
    }
  
    &:nth-child(3) {
      animation-delay: 0.4s;
    }
  }
  
  @keyframes bounce {
    to {
      transform: translateY(-0.25rem);
    }
  }
  
  .loadingSpinner {
    animation: spin 1s linear infinite;
    width: 1.5rem;
    height: 1.5rem;
  }
  
  .spinnerBg {
    opacity: 0.25;
  }
  
  .spinnerPath {
    opacity: 0.75;
  }
  
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  .pulseAnimation {
    position: relative;
    width: 1.5rem;
    height: 1.5rem;
  }
  
  .pulse {
    position: absolute;
    inset: 0;
    background-color: white;
    opacity: 0.75;
    border-radius: 50%;
    animation: pulse 1.5s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  }
  
  .pulseCore {
    position: absolute;
    inset: 0;
    background-color: white;
    border-radius: 50%;
  }
  
  @keyframes pulse {
    0% {
      transform: scale(0.95);
      opacity: 0.75;
    }
    50% {
      transform: scale(1);
      opacity: 0.5;
    }
    100% {
      transform: scale(0.95);
      opacity: 0.75;
    }
  }