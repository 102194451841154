// Dashboard.module.scss
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

.main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 1rem;
  overflow: scroll;
  // height: 100vh;
  height: calc(100vh - 40px);
}

/* Custom scrollbar styling */
.main::-webkit-scrollbar {
  width: 6px; /* Set the width of the scrollbar */
}

.main::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Rounds the corners of the thumb */
}

.main::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Darker color when hovering over the scrollbar */
}

.main::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color of the scrollbar track */
}

.appContainer{
  &.closed{
    padding-right: 58px;
  }
  &.open{
    padding-right: 300px;
  }
}


.dashboard {
  font-family: 'Inter', sans-serif;
  background-color: #f7f9fc;
  display: flex;
  flex-direction: column;
  
  &.darkMode {
      background-color: #1a202c;
      color: white;
  }
}

.statsSection {
  background-color: #fff;
  border-radius: 1rem;
  padding: 1.5rem;
  box-shadow: 0 4px 6px rgba(0,0,0,0.05);
  margin-bottom: 1.5rem;
  
  &.darkMode {
    background-color: #0d172b;
    color: white;

    .statsHeader {
      h2 {
        color: whitesmoke;
      }
      .icon {
        color: whitesmoke;
      }
    }
    .totalAmount {
      color: whitesmoke;
    }
  
    .subtitle {
      color: whitesmoke;
    }
  } 
  
  .statsHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    h2 {
      font-size: 1.25rem;
      font-weight: 600;
      color: #2d3748;
    }

    .icon {
      color: #4a5568;
      cursor: pointer;
    }
  }

  .totalAmount {
    font-size: 2rem;
    font-weight: 700;
    color: #2d3748;
    margin-bottom: 0.25rem;
  }

  .subtitle {
    color: #718096;
    font-size: 0.875rem;
    margin-bottom: 1rem;
  }
}

.quickActions {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  margin-bottom: 1.5rem;

  &.darkMode {
    .actionButton {
      background-color: #0d172b;
      color: white;
    }
  } 

  .actionButton {
    background-color: #fff;
    border: none;
    border-radius: 0.75rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 4px rgba(0,0,0,0.05);
    transition: all 0.2s ease;
    color: #4a5568;

    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    }

    svg {
      margin-bottom: 0.5rem;
    }
  }
}

.historySection {
  background-color: #fff;
  border-radius: 1rem;
  padding: 1.5rem;
  box-shadow: 0 4px 6px rgba(0,0,0,0.05);
  margin-bottom: 40px;
  &.darkMode {
    background-color: #0d172b;
    color: white;
    .historyHeader {
      h2,p{
        color: whitesmoke;
      }
    }
    .transactionInfo {
      flex-grow: 1;
  
      .transactionName {
        color: #fcfdff;
      }
  
      .transactionDate {
        color: #d7d7d7;
      }
    }
  } 

  .historyHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;

    h2 {
      font-size: 1.25rem;
      font-weight: 600;
      color: #2d3748;
    }

    .icon {
      color: #4a5568;
      cursor: pointer;
    }
  }
}

.transactionList {
  list-style-type: none;
  padding: 0;
}

.transactionItem {
  display: flex;
  align-items: center;
  padding: 1rem 0;
  border-bottom: 1px solid #e2e8f0;

  &:last-child {
    border-bottom: none;
  }

  .transactionIcon {
    font-size: 1.5rem;
    margin-right: 1rem;
    width: 2.5rem;
    height: 2.5rem;
    background-color: #edf2f7;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .transactionInfo {
    flex-grow: 1;

    .transactionName {
      font-weight: 500;
      color: #2d3748;
      margin-bottom: 0.25rem;
    }

    .transactionDate {
      font-size: 0.75rem;
      color: #718096;
    }
  }

  .transactionAmount {
    font-weight: 600;
    color: #000;
    &.expense{
      color: #e53e3e;
    }
    &.income{
      color: #1dcc17;
    }
  }
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
}

.statsSection,
.quickActions,
.historySection {
  animation: fadeIn 0.5s ease-out;
}