.actionButtons {
  width: 100%;
  max-width: 20rem;
}

.loginButton, .nextButton {
  width: 100%;
  padding: 0.5rem 0;
  border-radius: 4px;
  font-weight: 600;
  transition: background-color 0.3s, color 0.3s;
  border: none;
}

.loginButton {
  background-color: var(--color-primary);
  color: white;
  margin-bottom: 0.5rem;
  box-shadow: inset 0 0 5px 2px  var(--color-primary-dark);
  &.darkMode {
    background-color: var(--color-primary-dark);
  }
}

.nextButton {
  border: 2px solid black;
  color: #1a202c;
  background: none;
  
  &.darkMode {
    border: 2px solid #ffffff;
    color: white;
  }
}