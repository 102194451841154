.header {
    background-color: #fff;
    // padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    height: 44px;
    z-index: 1000;
    
    &.darkMode {
        background-color: #0d172b;
        color: whitesmoke;

        .icon {
            color: whitesmoke;
        }
        span{
            color: whitesmoke;
        }
        .opts{
            background: #0d172b;
            box-shadow: 0 4px 6px rgba(0,0,0,0.1);

            button{
                color: whitesmoke;
            }
        }     
    }
    
    .status {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: 20px;
      color: #4a5568;
      cursor: pointer;
      transition: color 0.2s ease;
      span{
        margin-right: 10px;
      }
    }
}


.userOpts{
    position: relative;
    padding: 0;
    button{
      background: none;
      border: none;
    }
  
    .mainHeaderBtn{
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      margin: 20px;
    }
  
    .opts{
      position: absolute;
      top: 0;
      left: 0;
      width: 300px;
      padding: 2rem;
      padding-bottom: 1.5rem;
      display: flex;
      flex-direction: column;
      background: rgb(252, 252, 252);
      box-shadow: 0 4px 6px rgba(0,0,0,0.1);
      z-index: 1000;

      .rowTop{
        display: flex;
        flex-direction: row;
        align-items: center;
        .icon{
          cursor: pointer;
        }

        .userDetails{
          display: flex;
          flex-direction: column;
          margin: 0 10px;
          span:first-of-type{
            font-weight: bold;
            font-size: small;
          }
          span:last-of-type{
            font-size: small;
          }
        }

        .themeToggle{
          display: block;
          width: 68px;
          margin: auto 0 auto auto;
        }
      }
      
      .heading{
        margin: 20px 0 4px 16px;
      }

      button{
        width: 100%;
        margin: 4px 0;
        padding: 4px;
        border: none;
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;

        &:hover {
          background: #c9ddff;
        }

        &.border_top{
          border-top: 1px solid rgb(223, 223, 223);
        }
        &.border_bottom{
          border-bottom: 1px solid rgb(223, 223, 223);
        }

        .icon{
          margin: 6px;
        }
      }
    }
  }