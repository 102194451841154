// LoginModal.module.scss
.message {
  padding: 0.75rem 1rem;
  border-radius: 0.375rem;
  margin-bottom: 1rem;
  font-size: 0.875rem;
  font-weight: 500;
  text-align: center;
}

.failed {
  background-color: #fee2e2;
  color: #b91c1c;
  border: 1px solid #f87171;
}

.succeeded {
  background-color: #dcfce7;
  color: #15803d;
  border: 1px solid #4ade80;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContainer {
  position: relative;
  background-color: white;
  padding: 2rem;
  border-radius: 0.5rem;
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;

  &.darkMode {
    background-color: var(--color-dark);
  }

  h2 {
    margin-bottom: 1rem;
    font-size: 1.5rem;
    font-weight: bold;
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    input {
      padding: 0.5rem;
      border: 1px solid #e2e8f0;
      border-radius: 0.25rem;

      &.darkMode {
        background-color: #4a5568;
        border-color: #718096;
        color: white;
      }
      &.error{
        border: 1px solid #f87171;
      }
    }

    button {
      padding: 0.5rem;
      background-color: var(--color-primary);
      color: white;
      border: none;
      border-radius: 0.25rem;
      cursor: pointer;
      transition: background-color 0.3s;

      &:hover {
        background-color: #3182ce;
      }

      &.darkMode {
        background-color: #2b6cb0;

        &:hover {
          background-color: #2c5282;
        }
      }
    }
  }

  p {
    margin-top: 1rem;
    text-align: center;

    button {
      background: none;
      border: none;
      color: var(--color-primary-dark);
      cursor: pointer;
      font-weight: bold;

      &.darkMode {
        color: #63b3ed;
      }
    }
  }
}

.googleButton {
  position: relative;
  background: #d7503f;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  color: white;
  padding: 8px;
  border-radius: 4px;
}

.closeButton {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background: none;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  color: #718096;

  .darkMode & {
    color: #a0aec0;
  }
}

.horizontalLineContainer {
  display: flex;
  align-items: center;
  margin: 24px 0;
}

.horizontalLine {
  flex-grow: 1;
  height: 1px;
  background-color: #cacaca; // You can customize the color
}

.centeredText {
  margin: 0 10px; // Adjust spacing between the text and the lines
  white-space: nowrap; // Prevent text from wrapping
}
