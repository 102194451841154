.toggle {
  // Base sizing using CSS custom properties
  --toggle-width: 5em;
  --toggle-height: 2.25em;
  --thumb-size: 1.5em;
  --transition-duration: 300ms;
  --padding: 0.25em;
  
  position: relative;

  &__container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__button {
    position: relative;
    width: var(--toggle-width);
    height: var(--toggle-height);
    border-radius: calc(var(--toggle-height) / 2);
    box-shadow: 0.3em 0.3em 0.3em #00000022;
    padding: var(--padding);
    background: none;
    border: none;
    cursor: pointer;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: calc(var(--toggle-height) / 2);
      box-shadow: inset 0.3em 0.3em 0.3em #0000000d;
      transition: background-color var(--transition-duration) ease-in-out;
    }

    &--light::before {
      background-color: var(--color-gray);
    }

    &--dark::before {
      background-color: #000;
    }
  }

  &__thumb {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: var(--thumb-size);
    height: var(--thumb-size);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform var(--transition-duration) ease-in-out,
      background-color var(--transition-duration) ease-in-out;
    background: white;

    &--light {
      transform: translateY(-50%) translateX(var(--padding));
    }

    &--dark {
      transform: translateY(-50%) 
        translateX(calc(var(--toggle-width) - var(--thumb-size) - var(--padding) * 2));
    }
  }

  &__icon {
    width: var(--thumb-size);
    height: var(--thumb-size);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;

    &--light {
      right: calc(var(--padding) + var(--thumb-size) * 0.1);
    }
    
    &--dark {
      left: calc(var(--padding) + var(--thumb-size) * 0.1);
    }
  }
}
