// Navigation.module.scss
.navigation {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 40vw;
  margin-bottom: 1rem;

  button{
    cursor: pointer;
  }
}

.navButton {
  padding: 0.5rem;
  border: 1px solid rgb(0, 0, 0);
  border-radius: 9999px;
  background-color: rgba($color: #000000, $alpha: 0);
  transition: background-color 0.3s;
  cursor: pointer;

  &.darkMode {
    border: 1px solid rgb(255, 255, 255);
  }
}

.indicators {
  display: flex;
  gap: 0.5rem;
}

.indicator {
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 9999px;
  border: 1px solid rgb(1, 1, 1);
  // background-color: #e2e8f0;
  transition: background-color 0.3s;

  &.active {
    background-color: #42e145;
  }

  &.darkMode {
    background-color: #ffffff;

    &.active {
      background-color: #42e145;
    }
  }
}

@media screen and (max-width: 660px) {
  .navigation {
    display: none;
  }
}
