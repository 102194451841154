.flex {
    display: flex;
  }
  
  .items-center {
    align-items: center;
  }
  
  .justify-between {
    justify-content: space-between;
  }
  
  .mt-4 {
    margin-top: 1rem;
  }
  
  .border-t {
    border-top: 1px solid #e5e7eb;
  }
  
  .pt-4 {
    padding-top: 1rem;
  }
  
  .space-x-2 {
    & > *:not(:last-child) {
      margin-right: 0.5rem;
    }
  }
  
  .select {
    border: 1px solid #d1d5db;
    border-radius: 0.25rem;
    padding: 0.25rem;
    background-color: #fff;
    cursor: pointer;
  
    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px rgba(59, 246, 90, 0.5);
    }
  }
  
  .text-sm {
    font-size: 0.875rem;
  }
  
  .text-gray-600 {
    color: #4b5563;
  }
  
  .button {
    padding: 0.25rem;
    border-radius: 0.25rem;
    cursor: pointer;
    transition: background-color 0.2s;
  
    &:hover {
      background-color: #f3f4f6;
    }
  
    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
  
  .page-button {
    width: 2rem;
    height: 2rem;
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.2s;
  
    &.active {
      background-color: var(--color-primary);
      color: #fff;
    }
  
    &:hover {
      background-color: var(--color-primary-dark);
    }
  }
  