// ExpenseForm.module.scss
.formContainer {
    max-width: 500px;
    margin: 0 auto;
    padding: 32px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .formTitle {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
    color: #efefef;
  }
  
  .form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .formGroup {
    display: flex;
    flex-direction: column;
  }

  .typeGroup{
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;

    .typeButton {
      width: 100%;
      gap: 10px;
      padding: 6px 8px;
      border: 1px solid #ccc;
      background: none;
      border-radius: 4px;
      font-size: medium;
      color: whitesmoke;

      &::placeholder{
        color: whitesmoke;
      }

      &.active{
        background-color: var(--color-primary);
      }

      &:focus {
        outline: none;
        border-color: #2fff00;
        box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
      }
    }
  }

  .label {
    font-size: small;
    font-weight: 500;
    margin-bottom: 5px;
    color: #efefef;
  }
  
  .input,
  .select {
    padding: 6px 8px;
    border: 1px solid #ccc;
    background: none;
    border-radius: 4px;
    font-size: medium;
    color: whitesmoke;
    color-scheme: light;
    &::placeholder{
      color: whitesmoke;
    }
    &:focus {
      outline: none;
      border-color: #2fff00;
      box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
    }
  }
  
  input[type="date"]::-webkit-calendar-picker-indicator {
    filter: invert(1); /* Makes the icon white */
    opacity: 0.8; /* Optional: adjust transparency */
  }
  
  .checkboxLabel {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #efefef;
  }
  
  .checkbox {
    margin-right: 8px;
  }
  
  .submitButton {
    padding: 10px 15px;
    background-color: var(--color-primary);
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.2s;
    font-weight: 600;
    &:hover {
      background-color: #0056b3;
    }
  }